<template>
  <div class="container-lg" >
    <div class="row mx-0">
      <div class="col-12 p-0">
        <div class="row m-0 justify-content-center align-items-center">
          <!-- <div class="col-auto p-0 gold-text">
            <IconLocation />
          </div> -->
          <div class="col-auto text-center page_title p-0">
            <div class="page_title">
              VERIFIED FUNDRAISERS
            </div>
            <div class="page_title_underline w-100 mt-3"></div>
          </div>
        </div>
        <div class="row mx-0 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 green-text fs-1 bold">
                Search for a verified fundraiser near you...
              </div>
            </div>
            <div class="row m-0 justify-content-center">
              <div class="col-12 col-sm-10 col-md-7 col-lg-7 col-xl-6 text-center">
                We maintain a system of verified fundraisers to protect your donations from being mishandled in our name. Please check the list below before donating to anyone claiming to raise funds for us.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FundraisersSection />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FundraisersSection: defineAsyncComponent(() => import('../../verifiedfundraisers/views/FundraisersSection.vue'))
  },
  name: 'FundraisersSeparateSection'
}
</script>

<style scoped>
.page_title {
  font-size: 14px;
  color: var(--gold-color);
  font-family: "quicksand_bold", Sans-serif;
  font-weight: bold;
  letter-spacing: 0.4rem;
  padding-right: 0.5rem;
  padding-left: 1rem;
  text-transform: uppercase;
}
.page_title_underline {
  border-bottom: 2px solid var(--gold-color);
}
@media (min-width: 992px) {
  .page_title {
    font-size: 21px;
  }
}
</style>
